<template>
  <v-dialog v-model="isModalOpen" width="900" persistent>
    <v-form ref="form" v-model="is_valid">
      <v-card v-if="currentOrderItem">
        <v-toolbar class="py-2" flat>
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">Add item</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-0">
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="d-flex flex-row">
            <v-img
              class="me-4"
              max-height="200"
              max-width="200"
              v-if="currentOrderItem?.image"
              :src="currentOrderItem?.image?.url"
            />
            <v-img
              v-else
              class="me-4"
              src="@/assets/product.png"
              max-height="100"
              max-width="100"
            />
            <div class="me-4">
              <label class="ma-1 font-weight-regular">{{
                currentOrderItem?.name
              }}</label>
              <br />
              <label class="text--disabled ma-1">
                ID: {{ currentOrderItem.code }} . Barcode :
                {{ currentOrderItem.barcode }}</label
              >
              <br />
              <p class="mt-2 font-weight-medium">
                {{ currentOrderItem?.qte_in_hub ?? 0 }} items in stock
              </p>
            </div>
            <div class="ml-auto me-6">
              <div
                class="
                  text-h5
                  font-weight-medium
                  d-flex
                  justify-space-between
                  py-4
                "
                style="min-width: 200px"
              >
                <span>Units per package</span>
                <span>{{ currentOrderItem?.product_info?.package_units }}</span>
              </div>
              <v-divider></v-divider>
              <div
                class="
                  text-h5
                  font-weight-medium
                  d-flex
                  justify-space-between
                  py-4
                "
                style="min-width: 200px"
              >
                <span>Package per palette</span>
                <span>{{ currentOrderItem?.product_info?.palette_units }}</span>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
          <v-row class="mt-9">
            <v-col class="d-flex" cols="12" sm="6" md="6" style="gap: 10px">
              <v-text-field
                dense
                hide-details="auto"
                outlined
                label="Quantity"
                v-model="currentOrderItem.qte_by_type_packaging"
                @keypress="isNumber($event)"
                :rules="[rules.quantity, rules.required]"
                @input="prepareProduct()"
              >
              </v-text-field>
              <v-autocomplete
                dense
                outlined
                class="d-flex font-weight-regular"
                label="Unit"
                v-model="currentOrderItem.type_packaging"
                :items="[
                  {
                    name: 'Item',
                    id: 'unit',
                  },
                  ...(this.currentOrderItem?.product_info?.package_units
                    ? [
                        {
                          name: 'Package',
                          id: 'pack',
                        },
                      ]
                    : []),
                  ...(this.currentOrderItem?.product_info?.palette_units
                    ? [
                        {
                          name: 'Palette',
                          id: 'pallet',
                        },
                      ]
                    : []),
                ]"
                item-value="id"
                item-text="name"
                :rules="[rules.required]"
                @change="prepareProduct()"
              ></v-autocomplete>
              <v-text-field
                dense
                hide-details="auto"
                outlined
                type="number"
                step="any"
                min="0"
                max="100"
                label="Discount %"
                v-model="currentOrderItem.discount"
                :rules="[rules.percentage]"
                @input="prepareProduct()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="orderItemsHeaders"
                :items="[currentOrderItem]"
                dense
                :hide-default-footer="true"
              >
                <template v-slot:item.discount="{ item }">
                  <span v-if="item.discount"> {{ item.discount }} % </span>
                </template>
                <template v-slot:item.tva="{ item }">
                  {{ item.tva }}%
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-6"
            color="#6100ea"
            @click="saveForm()"
            :disabled="
              !(
                currentOrderItem.total_quantity !== '' &&
                currentOrderItem.type_packaging !== ''
              )
            "
          >
            <span
              v-if="
                currentOrderItem.total_quantity !== '' &&
                currentOrderItem.type_packaging !== '' &&
                currentOrderItem.type_packaging !== 'unit'
              "
            >
              Add
              {{ currentOrderItem.qte_by_type_packaging }}
              {{ currentOrderItem.type_packaging }} To Cart
            </span>
            <span v-if="currentOrderItem.type_packaging == 'unit'">
              Add
              {{ currentOrderItem.qte_by_type_packaging }}
              Item To Cart
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
// import axios from "@/helpers/axios";
// const base_url = process.env.VUE_APP_API_BASE_URL;
import service from "@/store/services/supply-order-service";
import { Product } from "../../setup/products/product.class";

const calculquantity = (currentOrderItem) => {
  if (currentOrderItem.type_packaging == "pack") {
    return Number(
      currentOrderItem.qte_by_type_packaging *
        currentOrderItem.product_info.package_units
    );
  } else if (currentOrderItem.type_packaging == "pallet") {
    return Number(
      currentOrderItem.qte_by_type_packaging *
        currentOrderItem.product_info.palette_units *
        currentOrderItem.product_info.package_units
    );
  } else return currentOrderItem.qte_by_type_packaging;
};

export default {
  mixins: [validationRules],
  props: {
    isModalOpen: Boolean,
    orderItem: Object,
    toggleModal: Function,
  },
  mounted: function () {
    this.currentOrderItem = { ...this.orderItem };
    this.currentOrderItem.total_quantity = calculquantity(
      this.currentOrderItem
    );
    this.prepareProduct();
  },
  computed: {
    isVisibleDialog: {
      get() {
        return this.$store.state.products.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_DIALOG", value);
      },
    },
    editedSupplyOrder: {
      get() {
        return this.$store.state.supplyOrders.editedSupplyOrder;
      },
      set(value) {
        this.$store.commit("supplyOrders/EDITED_SUPPLY_ORDER", value);
      },
    },

    supplyOrder: {
      get() {
        return this.$store.state.supplyOrders.supplyOrder;
      },
      set(value) {
        this.$store.commit("supplyOrders/SupplyOrder", value);
        return value;
      },
    },

    calculquantity() {
      if (this.currentOrderItem.type_packaging == "pack") {
        return Number(
          this.currentOrderItem.qte_by_type_packaging *
            this.currentOrderItem.product_info.package_units
        );
      } else if (this.currentOrderItem.type_packaging == "pallet") {
        return Number(
          this.currentOrderItem.qte_by_type_packaging *
            this.currentOrderItem.product_info.palette_units *
            this.currentOrderItem.product_info.package_units
        );
      } else return this.currentOrderItem.qte_by_type_packaging;
    },

    total_price_ht() {
      return this.discount_price * this.calculquantity;
    },
    discount_price() {
      return (
        this.currentOrderItem.purchase_price_ht -
        this.currentOrderItem.purchase_price_ht *
          (this.currentOrderItem.discount / 100)
      );
    },
    total_price_TVA() {
      return (
        parseFloat(this.total_price_ht) * (this.currentOrderItem.tva / 100)
      );
    },
    total_price_TTC() {
      return this.total_price_TVA + parseFloat(this.total_price_ht);
    },
    purchase_price_TTC() {
      return this.discount_price * (1 + this.currentOrderItem.tva / 100);
    },
  },
  data() {
    return {
      product: new Product(),
      editUnit: null,
      message: null,
      currentOrderItem: null,
      qte_by_type_packaging: 0,
      is_valid: false,
      discount: 0,

      orderItemsHeaders: [
        { text: "Quantity (Units)", value: "total_quantity", align: "start" },
        { text: "Unit Price HT", value: "purchase_price_ht" },
        { text: "Discount", value: "discount" },
        { text: "Discount Price", value: "discount_price" },
        { text: "Total Price HT", value: "total_price_ht" },
        { text: "VAT", value: "tva" },
        { text: "Purchase Price TTC", value: "purchase_price" },
        { text: "Total price TTC", value: "total_price_TTC" },
      ],

      unit: [
        {
          name: "Unit",
          id: "unit",
        },
        ...(this.currentOrderItem?.product_info?.package_units
          ? [
              {
                name: "Package",
                id: "pack",
              },
            ]
          : []),
        ...(this.currentOrderItem?.product_info?.palette_units
          ? [
              {
                name: "Palette",
                id: "pallet",
              },
            ]
          : []),
      ],
    };
  },
  watch: {},

  methods: {
    close() {
      this.toggleModal();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    calculateQuantity() {
      if (this.currentOrderItem.type_packaging == "pack") {
        return Number(
          this.currentOrderItem.qte_by_type_packaging *
            this.currentOrderItem.product_info.package_units
        );
      } else if (this.currentOrderItem.type_packaging == "pallet") {
        return Number(
          this.currentOrderItem.qte_by_type_packaging *
            this.currentOrderItem.product_info.palette_units *
            this.currentOrderItem.product_info.package_units
        );
      } else return this.currentOrderItem.qte_by_type_packaging;
    },

    prepareProduct() {
      console.log("Prepare Product", this.currentOrder);

      if (
        !this.currentOrderItem.qte_by_type_packaging ||
        !this.currentOrderItem.type_packaging
      ) {
        return;
      }
      this.currentOrderItem.total_quantity = this.calculateQuantity();
      const orderItem = {
        qte_by_type_packaging: this.currentOrderItem.qte_by_type_packaging,
        quantity: this.calculquantity,
        supply_order_tva: this.currentOrderItem.tva,
        supply_order_price_ht: this.currentOrderItem.purchase_price_ht,
        total_price_ht: Number(this.total_price_ht).toFixed(2),
        total_price_TTC: Number(this.total_price_TTC).toFixed(2),
        purchase_price: Number(this.purchase_price_TTC).toFixed(2),
        code: this.currentOrderItem.code,
        id: this.currentOrderItem.id,
        discount: this.currentOrderItem.discount,
        type_packaging: this.currentOrderItem.type_packaging,
        supply_order_id: this.editedSupplyOrder.id,
      };
      if (this.currentOrderItem.discount != 0) {
        const orderItemWithdiscount = {
          discount_price: Number(this.discount_price).toFixed(2),
        };
        Object.assign(orderItem, orderItemWithdiscount);
      } else {
        const orderItemWithoutdiscount = {
          discount_price: null,
        };
        Object.assign(orderItem, orderItemWithoutdiscount);
      }
      Object.assign(this.currentOrderItem, orderItem);
    },
    validate() {
      this.$refs.form.validate();
    },
    async saveForm() {
      this.validate();
      if (this.is_valid) {
        try {
          if (!this?.supplyOrder?.id) {
            const createdSupplyOrder = await service.add({
              ...this.supplyOrder,
              shelve_id: this.supplyOrder.shelf_id,
            });
            this.supplyOrder.id = createdSupplyOrder.id;
          }
          this.currentOrderItem.supply_order_id = this.supplyOrder.id;
          // this.currentOrderItem.discount = this.discount;
          const orderItem = {
            id: this.currentOrderItem.id,
            qte_by_type_packaging: this.currentOrderItem.qte_by_type_packaging,
            type_packaging: this.currentOrderItem.type_packaging,
            supply_order_id: this.supplyOrder.id,
            discount: this.currentOrderItem.discount,
          };

          this.$store.dispatch("supplyOrders/addToCart", orderItem);
          this.toggleModal();
          this.$store.dispatch(
            "alerts/success",
            this.$t("Item added successfully")
          );
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
  },
};
</script>
